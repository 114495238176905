import {graphql} from "gatsby"
import React from "react"
import Layout from "../Page"
import Elements from '../../templates/Elements/Elements';
import Swiper from 'react-id-swiper';
import Masonry from "react-masonry-component"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { getUser, isLoggedIn } from "../../services/auth"

const ElementalPage = ({ data: { silverStripeDataObject }}) => {
    const { title, canViewType, showInSearch } = silverStripeDataObject.SilverStripeSiteTree;
    const { elementalContent } = silverStripeDataObject.ElementalPage;
    const { bannerTitle, bannerSubTitle, bannerImageURL, metaTags} = silverStripeDataObject.Page;
    var validPermissions = ['Anyone', 'Inherit'];
    if(!validPermissions.includes(canViewType)){
        if(!isLoggedIn()) {
            if (typeof window !== 'undefined') {
                window.location.href = "/login/";
            }
        }
    }
    const pageData = {
        pageClass: 'ElementalPage', 
        bodyClass: '', 
        pageTitle: '',
        metaTags: metaTags,      
    };
    return (
        <Layout pageData={pageData}>
            <Elements data={elementalContent}/>
        </Layout>
    );
};

export default ElementalPage
export const pageQuery = graphql`
    query ($link: String!) {
        silverStripeDataObject(link: { eq: $link }) {
            ElementalPage {
                elementalContent
            }
            SilverStripeSiteTree {
                title
                canViewType
                showInSearch
            }     
            Page{
                bannerTitle
                bannerSubTitle
                bannerImageURL
                metaTags
            }
        }
    }
`;